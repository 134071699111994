<!-- AdminCareSeekers.vue -->
<template>
  <!-- start caregiver profile -->
  <div v-if="isCareGiverChosen" class="caregiver-details">
    <div @click="backtoDashboard" class="caregiver-details-first-div">
      <svg
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.70492 1.41L6.29492 0L0.294922 6L6.29492 12L7.70492 10.59L3.12492 6L7.70492 1.41Z"
          fill="black"
        />
      </svg>
      <p>Back to Dashboard</p>
    </div>

    <!-- start -->
    <div class="main-container">
      <div class="main-container-section">
        <div class="image-header">
          <div class="profile-section">
            <div v-if="isCareGiverChosen" class="profile-image">
              <!-- <img src="@/assets/woman.png" alt="Profile Image" /> -->
              <img :src="jobDetails.profile_picture" alt="Profile Image" />
            </div>
            <div>
              <div>
                <span class="span-header"> Name: </span>
                <span class="span-normal"> {{ jobDetails.name }}</span>
              </div>
              <div>
                <span class="span-header"> Gender: </span>
                <span class="span-normal"> {{ jobDetails.gender }}</span>
              </div>
              <div v-if="isCareGiverChosen">
                <span class="span-header"> Location: </span>
                <span class="span-normal">{{ jobDetails.location }} </span>
              </div>
              <div>
                <span class="span-header"> Email address: </span>
                <span class="span-normal">{{ jobDetails.email }}</span>
              </div>
              <div>
                <span class="span-header"> Phone Number: </span>
                <span class="span-normal">{{ jobDetails.phone }}</span>
              </div>
            </div>
          </div>
          <div v-if="isCareGiverChosen" class="the-div">Caregiver</div>
          <div v-else class="the-div">Careseeker</div>
        </div>

        <h2 v-if="isCareGiverChosen">Bio</h2>

        <div v-if="isCareGiverChosen" class="content-section">
          <p>{{ jobDetails.description }}</p>
        </div>

        <div v-if="isCareGiverChosen" class="content-section">
          <p class="header-text">Categories</p>
          <!-- <p v-else class="header-text">Care services needed</p> -->
          <div class="jobs-list-item-type-of-care">
            <!-- <h4>Senior Care Needed</h4> -->
            <p>{{ jobDetails.categories }}</p>
            <div class="job-tag">
              <span
                v-for="sub_care in jobDetails.sub_care_categories"
                :key="sub_care"
                >{{ sub_care.name }}</span
              >
            </div>
          </div>
        </div>

        <div v-if="isCareGiverChosen" class="content-section">
          <div class="jobs-list-item-type-of-care-container">
            <div style="width: 300px">
              <p class="header-text">Availability</p>
              <span>{{ jobDetails.availability }}</span>
              <!-- <span>Mornings</span> -->
            </div>

            <div>
              <p class="header-text">Rate</p>
              <p>{{ jobDetails.rate }}</p>
            </div>
          </div>
        </div>
        <div class="content-section">
          <div class="jobs-list-item-type-of-care-container">
            <div v-if="isCareGiverChosen" style="width: 300px">
              <p class="header-text">Education</p>
              <span>{{ jobDetails.education }}</span>
              <!-- <span>Mornings</span> -->
              <div
                style="
                  display: flex;
                  gap: 10px;
                  align-items: center;
                  margin-top: 8px;
                "
              >
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0H2C0.9 0 0.0100002 0.9 0.0100002 2L0 18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6L10 0ZM2 18V2H9V7H14V18H2Z"
                    fill="#979797"
                  />
                </svg>
                <a
                  :href="jobDetails.certificate"
                  target="_blank"
                  style="color: var(--state-info); text-decoration: none"
                >
                  View Certificate
                </a>
              </div>
            </div>

            <div v-if="isCareGiverChosen">
              <p class="header-text">Preferences</p>
              <p>{{ jobDetails.preferences }}</p>
            </div>
          </div>
        </div>
        <div v-if="isCareGiverChosen" class="content-section">
          <p class="header-text">Language</p>
          <div class="jobs-list-item-type-of-care">
            <!-- <h4>Senior Care Needed</h4> -->
            <div class="job-tag">
              <span v-for="language in jobDetails.language" :key="language">{{
                language.name
              }}</span>
            </div>
          </div>
        </div>

        <div class="content-section">
          <div class="jobs-list-item-type-of-care-container">
            <div style="width: 300px">
              <p class="header-text">Verification</p>
              <span>{{ jobDetails.document_type }} : </span>
              <span>{{ jobDetails.document_number }}</span>
            </div>
          </div>
        </div>
        <div v-if="isCareGiverChosen" class="content-section">
          <p class="header-text">Guarantor's details</p>
          <div>
            <span class="span-header"> Name: </span>
            <span class="span-normal">{{ jobDetails.guarantor.name }}</span>
          </div>
          <div>
            <span class="span-header"> Designation: </span>
            <span class="span-normal">
              {{ jobDetails.guarantor.designation }}</span
            >
          </div>
          <div>
            <span class="span-header"> Work address: </span>
            <span class="span-normal">{{ jobDetails.guarantor.address }} </span>
          </div>
          <div>
            <span class="span-header"> Email address: </span>
            <span class="span-normal">{{ jobDetails.guarantor.email }} </span>
          </div>
          <div>
            <span class="span-header"> Phone Number: </span>
            <span class="span-normal">{{ jobDetails.guarantor.phone }} </span>
          </div>
        </div>
        <!-- end -->
      </div>
    </div>
    <div class="button-div">
      <BaseButton
        :label="isApproving ? 'Approving...' : 'Approve'"
        backgroundColor="var(--s-pink-normal)"
        textColor="var(--state-white)"
        height="60px"
        :width="isLargeScreen ? '400px' : '260px'"
        @click="handleApproval"
      />
      <BaseButton
        label="Cancel"
        backgroundColor="transparent"
        textColor="var(--s-green-normal-active)"
        height="60px"
        :width="isLargeScreen ? '400px' : '260px'"
        borderColor="var(--s-green-normal-active)"
        @click="backtoDashboard"
      />
    </div>
  </div>
  <!-- end caregiver profile -->
  <div v-else class="main-table-container">
    <h2>Care seekers</h2>
    <LoadingSpinner :isLoading="isLoading" />
    <ToastManagerVue ref="toastManager" />
    <div class="careseekers-table-container">
      <!-- ...other components like filters... -->
      <UniversalTable :endpoint="endpoint" :columns="columns">
        <!-- Custom cell rendering using scoped slots -->
        <template #cell-approval_status="{ row }">
          <span :class="row.approval_status == 1 ? 'active' : 'inactive'">
            {{ row.approval_status == 1 ? "Active" : "Inactive" }}
          </span>
        </template>

        <template #cell-action="{ row, index }">
          <div class="action-cell-container">
            <span class="icon-dots" @click="toggleDelistDropdown(index)">
              <svg
                width="16"
                height="4"
                viewBox="0 0 16 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM14 0C12.9 0 12 0.9 12 2C12 3.1 12.9 4 14 4C15.1 4 16 3.1 16 2C16 0.9 15.1 0 14 0ZM6 2C6 0.9 6.9 0 8 0C9.1 0 10 0.9 10 2C10 3.1 9.1 4 8 4C6.9 4 6 3.1 6 2Z"
                  fill="black"
                />
              </svg>
            </span>
            <div v-if="actionDropdown === index" class="dropdown-menu">
              <!-- <button @click="delistCaregiver(row.id)">
                <span class="icon"
                  ><svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM1.6 8C1.6 4.464 4.464 1.6 8 1.6C9.48 1.6 10.84 2.104 11.92 2.952L2.952 11.92C2.104 10.84 1.6 9.48 1.6 8ZM8 14.4C6.52 14.4 5.16 13.896 4.08 13.048L13.048 4.08C13.896 5.16 14.4 6.52 14.4 8C14.4 11.536 11.536 14.4 8 14.4Z"
                      fill="black"
                    />
                  </svg>
                </span>
                Delist careseeker
              </button> -->
              <button @click="viewMatchedCaregivers(row.id)">
                <span class="icon"
                  ><svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM1.6 8C1.6 4.464 4.464 1.6 8 1.6C9.48 1.6 10.84 2.104 11.92 2.952L2.952 11.92C2.104 10.84 1.6 9.48 1.6 8ZM8 14.4C6.52 14.4 5.16 13.896 4.08 13.048L13.048 4.08C13.896 5.16 14.4 6.52 14.4 8C14.4 11.536 11.536 14.4 8 14.4Z"
                      fill="black"
                    />
                  </svg>
                </span>
                View Matched Caregivers
              </button>
            </div>
          </div>
        </template>
      </UniversalTable>
    </div>
    <GenericModal
      :isVisible="isMainModalVisible"
      :title="modalTitle"
      @close="hideModal"
      maxWidth="1000px"
    >
      <!-- Use a loading condition before showing any caregivers or the empty state -->
      <div
        v-if="isLoading"
        style="display: flex; justify-content: center; align-items: center"
      >
        <LoadingSpinner :isLoading="isLoading" />
      </div>

      <div v-else-if="matchedCaregivers && matchedCaregivers.length > 0">
        <table class="matched-caregivers-table data-table">
          <thead>
            <tr>
              <th>S/NO</th>
              <th>Name</th>
              <th>Gender</th>
              <th>Phone</th>
              <th>Email</th>
              <th>View Details</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(caregiver, index) in matchedCaregivers"
              :key="caregiver.id"
            >
              <td>{{ index + 1 }}</td>
              <td>
                {{ caregiver.user.first_name }} {{ caregiver.user.last_name }}
              </td>
              <td>{{ caregiver.user.gender }}</td>
              <td>{{ caregiver.user.phone }}</td>
              <td>{{ caregiver.user.email }}</td>
              <td>
                <div class="view-details" @click="showCaregiver(caregiver.id)">
                  View Details
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        v-else
        style="display: flex; justify-content: center; align-items: center"
      >
        No matched caregivers found.
      </div>
    </GenericModal>
  </div>

  <!-- end -->
</template>

<script>
import { ref } from "vue";
import UniversalTable from "@/components/UniversalTable.vue";
import Request from "@/utils/apiclient.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ToastManagerVue from "@/components/ToastAlert/ToastManager.vue";
import GenericModal from "@/components/GenericModal.vue";
import { formatErrorMessages } from "@/utils/errorHandler";

export default {
  name: "AdminCareSeekers",
  components: {
    UniversalTable,
    GenericModal,
    LoadingSpinner,
    ToastManagerVue,
  },
  setup() {
    const isMainModalVisible = ref(false);

    const toastManager = ref(null);
    const showToast = (msg, statusValue) => {
      toastManager.value.addToast(msg, statusValue);
    };
    const isLoading = ref(false);

    const endpoint = "/admin/care-seekers/";

    const columns = [
      { label: "ID", field: "id" },
      {
        label: "Name",
        field: "name",
        render: (row) => `${row.user.first_name} ${row.user.last_name}`,
      },
      { label: "Category", field: "category.name" },
      { label: "Status", field: "approval_status" },
      {
        label: "Access",
        field: "has_access",
        render: (row) => (row.has_access ? "Has Access" : "No Access"),
      },
      { label: "Action", field: "action" },
    ];

    // Functions for filters, actions, etc.

    const actionDropdown = ref(null);

    const toggleDelistDropdown = (index) => {
      console.log("I got clicked", index);
      actionDropdown.value = actionDropdown.value === index ? null : index;
    };

    const delistCaregiver = (id) => {
      handleDelist(id);
      actionDropdown.value = null;
    };

    const handleDelist = async (id) => {
      actionDropdown.value = null;
      isLoading.value = true;
      const formData = {
        approval_status: 2,
      };
      const request = new Request(`/admin/care-seekers/${id}/`);
      const rawData = JSON.stringify(formData);
      request.patch(rawData, (data, error) => {
        isLoading.value = false;
        if (error) {
          if (error.status == "failure") {
            return showToast(error.message, "error");
          }
          if (error.detail) {
            return showToast("Error delisting careseeker", "error");
          }
          const errorMessages = formatErrorMessages(error);
          errorMessages.forEach((message) => showToast(message, "error"));
        } else {
          showToast("Careseeker delisted successfully", "success");
          // Optionally, refetch data or update local state
        }
      });
    };
    const matchedCaregivers = ref([]);
    const modalTitle = ref("Matched Caregivers");

    const viewMatchedCaregivers = async (id) => {
      console.log("careseeker id", id);
      actionDropdown.value = null;
      isMainModalVisible.value = true;
      isLoading.value = true;

      const request = new Request(
        `/admin/care-seeker/match/care-givers?care_seeker_id=${id}`
      );
      request.get((data, error) => {
        isLoading.value = false;
        if (error) {
          console.log("error here 000000", error);
          // if (error.status == "failure") {
          //   return showToast(error.message, "error");
          // }
          // if (error.detail) {
          //   return showToast("Error fetching matched caregivers", "error");
          // }
          // const errorMessages = formatErrorMessages(error);
          // errorMessages.forEach((message) => showToast(message, "error"));
        } else {
          console.log("data here for matched", data);
          matchedCaregivers.value = data.data || [];

          modalTitle.value = `Matched Caregivers (${matchedCaregivers.value.length})`;
        }
      });
    };

    const hideModal = () => {
      isMainModalVisible.value = false;
    };
    const isCareGiverChosen = ref(false);
    const isCareTypeChosen = ref(true);
 const jobDetails = ref({
      description: "",
      name: "",
      gender: "",
      location: "",
      email: "",
      type_of_care: "",
      sub_care_categories: [],
      categories: [],
      language: [],
      rate: "",
      start_date: "",
      preferences: "",
      education: "",
      certificate: "",
      profile_picture: "",
      guarantor: {
        first_name: "",
        last_name: "",
        address: "",
        email: "",
      },
      document_type: "",
      document_number: "",
      degree_obtained: "",
      hear_about_us: "",
      jobs: [],
    });

    const showCaregiver = (id) => {
      isCareGiverChosen.value = true;
      console.log("id here", id);
      console.log("matchedCaregivers", matchedCaregivers.value);
      const req = matchedCaregivers.value.find((care) => care.id === id);

       jobDetails.value.description = req.bio;
      jobDetails.value.type_of_care = "Caregiver";
      jobDetails.value.sub_care_categories = [...req.sub_category];
      jobDetails.value.language = [...req.language];
      jobDetails.value.rate = req.monthly_rate;
      jobDetails.value.preferences = req.work_preference;
      jobDetails.value.document_number = req.document_number;
      jobDetails.value.certificate = req.certificate;
      jobDetails.value.availability = req.availabilities[0].days.join(", ");
      jobDetails.value.name = req.user.first_name + " " + req.user.last_name;
      jobDetails.value.gender = req.user.gender;
      jobDetails.value.location = req.lga + ", " + req.state;
      jobDetails.value.email = req.user.email;
      jobDetails.value.phone = req.user.phone;
      jobDetails.value.document_type = req.document_type;
      jobDetails.value.degree_obtained = req.degree_obtained;
      jobDetails.value.education = req.education;
      jobDetails.value.profile_picture = req.user.profile_picture;
      jobDetails.value.categories = [
        ...req.category.map((val) => val.name),
      ].join(", ");
      jobDetails.value.guarantor.name =
        req.guarantor.first_name + " " + req.guarantor.last_name;
      jobDetails.value.guarantor.designation = req.guarantor.designation;
      jobDetails.value.guarantor.email = req.guarantor.email;
      jobDetails.value.guarantor.address = req.guarantor.work_address;
      jobDetails.value.guarantor.address = req.guarantor.work_address;
      jobDetails.value.guarantor.phone = req.guarantor.phone;
    
    };

    const backtoDashboard = () => {
      isCareGiverChosen.value = false;

      
    };
    return {
      endpoint,
      columns,
      isLoading,
      actionDropdown,
      toggleDelistDropdown,
      delistCaregiver,
      showToast,
      toastManager,
      isMainModalVisible,
      modalTitle,
      hideModal,
      viewMatchedCaregivers,
      matchedCaregivers,
      showCaregiver,
      backtoDashboard,
      isCareGiverChosen,
      isCareTypeChosen,
      jobDetails
    };
  },
};
</script>


<style scoped>
.view-details{
  display: flex;
  background-color: var(--s-pink-normal);
  padding: 5px 10px;
  border-radius: 8px;
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer
}
.modal-content {
  max-width: 600px;
}
.main-table-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 1008px;
  margin-top: 52px;
}

.filter-container {
  margin-bottom: 20px;
}

.custom-select-wrapper {
  position: relative;
  display: inline-block;
  width: 340px;
}

.custom-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #979797;
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
}

.custom-options {
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 340px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  margin-top: 8px;
  z-index: 10;
  list-style-type: none;
  padding: 0;
}

.custom-options li {
  padding: 8px;
  cursor: pointer;
}

.custom-options li:hover,
.custom-options li.selected {
  background-color: #f4f4f4;
}

.careseekers-table {
  width: 100%;

  border-spacing: 0 13px;
  /* opacity: 0.5; */
}
.careseekers-table-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-radius: 12px;
  padding: 24px;
  background-color: #ececec80;
  min-height: 772px;
  margin-bottom: 400px;
}

.careseekers-table th {
  /* opacity: 1; */
  padding: 8px;

  font-size: 16px;
  font-weight: 500;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}

.careseekers-table td {
  padding: 8px;

  font-size: 16px;
  font-weight: 400;

  letter-spacing: 0.5px;
  text-align: left;
}

.careseekers-table th {
  background-color: #f4f4f4;
}

.careseekers-table td.active {
  width: 70px;
  height: 35px;
  background-color: var(--state-success);
  color: var(--state-white);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.careseekers-table td.inactive {
  width: 83px;
  height: 35px;
  padding: 8px;
  background-color: #979797;
  color: var(--state-white);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.dropdown-menu {
  position: absolute;
  right: 60px; /* Align to the right edge of the icon-dots container */
  top: -30px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0px 2px 4px 0px #0000001a;
  padding: 10px;

  z-index: 10;
}

.dropdown-menu button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px 8px;
  width: 100%;
  text-align: left;
}

.dropdown-menu .icon {
  margin-right: 8px;
}

.selected-option {
  display: flex;
  align-items: center;
}

.selected-option svg {
  margin-right: 8px;
}

.the-td {
  position: relative;
}

.icon-dots {
  display: flex;
  padding-left: 12px;

  cursor: pointer;
}
/* Pagination styles (commented out for now) */
/*
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination button {
  padding: 8px 16px;
  border: 1px solid #ddd;
  background-color: white;
  cursor: pointer;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
*/
h2 {
  margin-top: 50px;
}
.action-cell-container {
  position: relative; /* Creates a positioning context for absolute children */
}

.dropdown-menu {
  position: absolute;
  top: 200%; /* Place directly below the icon */
  /* right: 0;  */
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0px 2px 4px 0px #0000001a;
  padding: 10px;
  z-index: 10;
}
.action-cell-container {
  position: relative; /* Creates a positioning context for absolute children */
}

.data-table {
  width: 100%;

  border-collapse: collapse;
}

.data-table th,
.data-table td {
  padding: 8px;
  /* border: 1px solid #ddd; */
}
.table-container {
  padding: 20px;
  width: 100%;
}

.data-table {
  width: 100%;
  border-collapse: collapse; /* Ensure borders are collapsed */
  /* table-layout: auto; */
  /* table-layout: fixed; */
}

.data-table th,
.data-table td {
  padding: 10px 15px; /* Add consistent padding */
  text-align: left; /* Align text to the left */
}

h5 {
  font-size: 16px;
}
.job-category-class {
  display: flex;
  /* gap: 20px; */

  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 10px;
}
h1 {
  margin: 0px 0px 32px 0px;
}
.class-container {
  display: flex;
  width: 100%;
}
.content-container {
  display: flex;
  flex-direction: column;
  padding-left: 14px;
  margin-top: 72px;
  box-sizing: border-box;
}
.content-container-categories {
  display: flex;

  gap: 24px;
}
.content-container-categories-main {
  display: flex;
  justify-content: space-between;
  background-color: var(--s-green-normal);
  width: 470px;
  height: 148px;
  padding: 16px 24px 0px 24px;
  box-sizing: border-box;
  border-radius: 8.14px;
}
.svg-div {
  display: flex;
  align-items: flex-end;
}
h4 {
  margin: 0px;
  font-family: Inter;
  font-size: 24.43px;
  font-weight: 400;
  line-height: 23.07px;
  letter-spacing: 0.3392857015132904px;
  text-align: left;
  color: var(--state-white);
}

.carecategory-number {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 18.5px 0px 34.5px 0px;
  gap: 16px;
}
h1 {
  font-size: 44px;
  font-weight: 700;
  line-height: 57.2px;
  letter-spacing: 0.5px;
  text-align: left;
}

.stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}

.stat-card {
  background-color: #c8e6c9;
  padding: 16px;
  flex: 1;
  margin: 0 8px;
  text-align: center;
}

.requests-reports {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.requests,
.reports {
  margin-bottom: 16px;
}

.request-item,
.report-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #ececec;
  margin-top: 8px;
  box-sizing: border-box;
}

.request-item a,
.report-item a {
  color: #1e88e5;
  text-decoration: none;
}

.request-item a:hover,
.report-item a:hover {
  text-decoration: underline;
}
.toggle-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 6px;
  border-bottom: 1px solid #979797;
}
.toggle-control {
  cursor: pointer;
}
h3 {
  color: var(--s-green-normal);
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
}
.text {
  font-family: Inter;
  font-size: 40px;
  font-weight: 700;
  line-height: 38.81px;
  letter-spacing: 0.3392857015132904px;
  text-align: left;
  color: var(--state-white);
}
.caregiver-details-first-div {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.caregiver-details {
  padding-left: 46px;
  margin-top: 30px;
}
.view-request {
  color: var(--state-info);
  text-decoration: underline;
  cursor: pointer;
}

.jobs-list-item-first-div {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  margin-top: 16px;
}
.sub-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}
.main-container-section {
  display: flex;
  width: 839px;
  flex-direction: column;
}
.main-container {
  padding: 20px;
  background-color: var(--state-white);
  border-radius: 12px;
  width: 100%;
   max-width: 950px;
  margin-top: 22px;
}
h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  text-align: left;
  margin: 0px 0px 0px 0px;
}
.header-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  margin-bottom: 16px;
  text-align: justified;
}
p {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.job-tag {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.job-tag span {
  padding: 10px;
  gap: 8px;
  border-radius: 12px;
  background-color: #ececec;
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}
.jobs-list-item-type-of-care {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.schedule-container {
  display: flex;
  align-items: center;
  gap: 24px;
}
.start-end {
  display: flex;
  flex-direction: column;
  gap: 9px;
}
.content-section:last-child {
  border-bottom: 0px;
}
.content-section {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #979797;
  width: 100%;
}
.jobs-list-item-type-of-care-container {
  display: flex;
  gap: 152px;
}
.span-header {
  font-size: 16px;
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.span-normal {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
}
.button-div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 129px;
  margin-top: 20px;
}
.profile-image-container {
  display: flex;
  justify-content: center;
}
.profile-image {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.profile-image img {
  width: 100px;
  height: 100px;
  border-radius: 12px;
}
.image-header {
  display: flex;
  justify-content: space-between;
}
.profile-section {
  display: flex;
  gap: 24px;
  align-items: center;
}
.the-div {
  width: 138px;
  height: 44px;
  padding: 10px;
  gap: 10px;
  border-radius: 12px;
  background-color: var(--s-pink-light-active);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
@media (max-width: 800px) {
  .content-container-categories {
    flex-direction: column;
  }
  .content-container-categories-main {
    width: 260px;
  }
  .main-container-section {
    width: 260px;
  }

  h4 {
    font-size: 18px;
  }
  .requests-reports {
    margin-top: 20px;
  }
  h1 {
    font-size: 35px;
  }
  .content-container {
    margin-top: 30px;
  }
  .button-div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 129px;
    margin-top: 20px;
    gap: 20px;
  }
  .caregiver-details {
    padding-left: 0px;
  }
  .profile-section {
    flex-direction: column;
  }
  .image-header {
    flex-direction: column;
  }
  .jobs-list-item-type-of-care-container {
    flex-direction: column;
  }
}

@media (max-width: 800px) {
  .main-table-container {
    width: 280px;
  }
  .careseekers-table {
    border-spacing: 0px 5px;
  }

  .careseekers-table td.active {
    width: 30px;
    height: 25px;
    background-color: var(--state-success);
    color: var(--state-white);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  .careseekers-table td.inactive {
    width: 33px;
    height: 25px;
    padding: 8px;
    background-color: #979797;
    color: var(--state-white);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  .careseekers-table th {
    /* opacity: 1; */
    padding: 8px;

    font-size: 7px;
    font-weight: 500;
    line-height: 27.2px;
    letter-spacing: 0.5px;
    text-align: left;
  }

  .careseekers-table td {
    padding: 8px;

    font-size: 7px;
    font-weight: 400;

    letter-spacing: 0.5px;
    text-align: left;
  }
  .custom-select-wrapper {
    width: 200px;
  }
}
</style>
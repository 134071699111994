<template>
  <div class="main-table-container">
    <LoadingSpinner :isLoading="isLoading" />
    <ToastManagerVue ref="toastManager" />
    <h2 style="margin-bottom: 10px">Termination Requests</h2>
    <div class="filter-container">
      <!-- You can add more filters here using similar dropdowns -->
    </div>
    <div class="careseekers-table-container">
      <table class="careseekers-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Caregiver Name</th>
            <th>Careseeker Name</th>
            <th>Termination Reason</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(careseeker, index) in caregivers" :key="careseeker.id">
            <td>{{ index + 1 }}</td>
            <td>
              {{ careseeker.care_giver.user.first_name }}
              {{ careseeker.care_giver.user.last_name }}
            </td>
            <td>
              {{ careseeker.care_seeker.user.first_name }}
              {{ careseeker.care_seeker.user.last_name }}
            </td>
            <td>
              {{ careseeker.termination_reason }}
            </td>
            <td class="the-td">
              <span class="icon-dots" @click="toggleDelistDropdown(index)">
                <svg
                  width="16"
                  height="4"
                  viewBox="0 0 16 4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM14 0C12.9 0 12 0.9 12 2C12 3.1 12.9 4 14 4C15.1 4 16 3.1 16 2C16 0.9 15.1 0 14 0ZM6 2C6 0.9 6.9 0 8 0C9.1 0 10 0.9 10 2C10 3.1 9.1 4 8 4C6.9 4 6 3.1 6 2Z"
                    fill="black"
                  />
                </svg>
              </span>
              <div v-if="actionDropdown === index" class="dropdown-menu">
                <button @click="handleApprove('approve', careseeker.id)">
                  <span class="icon"
                    ><svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM1.6 8C1.6 4.464 4.464 1.6 8 1.6C9.48 1.6 10.84 2.104 11.92 2.952L2.952 11.92C2.104 10.84 1.6 9.48 1.6 8ZM8 14.4C6.52 14.4 5.16 13.896 4.08 13.048L13.048 4.08C13.896 5.16 14.4 6.52 14.4 8C14.4 11.536 11.536 14.4 8 14.4Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  Approve Termination Request
                </button>
                <button @click="handleApprove('rejecte', careseeker.id)">
                  <span class="icon"
                    ><svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM1.6 8C1.6 4.464 4.464 1.6 8 1.6C9.48 1.6 10.84 2.104 11.92 2.952L2.952 11.92C2.104 10.84 1.6 9.48 1.6 8ZM8 14.4C6.52 14.4 5.16 13.896 4.08 13.048L13.048 4.08C13.896 5.16 14.4 6.52 14.4 8C14.4 11.536 11.536 14.4 8 14.4Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  Reject Termination Request
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
        <div class="careseekers-table-container">
        <!-- ...other components like filters... -->
        <UniversalTable :endpoint="endpoint" :columns="columns">
          <!-- Custom cell rendering using scoped slots -->
          <!-- <template #cell-approval_status="{ row }">
            <span :class="row.approval_status == 1 ? 'active' : 'inactive'">
              {{ row.approval_status == 1 ? "Active" : "Inactive" }}
            </span>
          </template> -->

          <template #cell-action="{ row, index }">
            <div class="action-cell-container">
            <span class="icon-dots" @click="toggleReportsDropdown(row.id)">
                  <svg
                    width="16"
                    height="4"
                    viewBox="0 0 16 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM14 0C12.9 0 12 0.9 12 2C12 3.1 12.9 4 14 4C15.1 4 16 3.1 16 2C16 0.9 15.1 0 14 0ZM6 2C6 0.9 6.9 0 8 0C9.1 0 10 0.9 10 2C10 3.1 9.1 4 8 4C6.9 4 6 3.1 6 2Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <div v-if="actionDropdown === index" class="dropdown-menu">
                  <button @click="inputScreeningReport(row)">
                    Input Screening report
                  </button>
                </div>



              <span class="icon-dots" @click="toggleDelistDropdown(index)">
                <svg
                  width="16"
                  height="4"
                  viewBox="0 0 16 4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM14 0C12.9 0 12 0.9 12 2C12 3.1 12.9 4 14 4C15.1 4 16 3.1 16 2C16 0.9 15.1 0 14 0ZM6 2C6 0.9 6.9 0 8 0C9.1 0 10 0.9 10 2C10 3.1 9.1 4 8 4C6.9 4 6 3.1 6 2Z"
                    fill="black"
                  />
                </svg>
              </span>
              <div v-if="actionDropdown === index" class="dropdown-menu">
                <button @click="viewMatchedCaregivers(row.id)">
                  <span class="icon"
                    ><svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM1.6 8C1.6 4.464 4.464 1.6 8 1.6C9.48 1.6 10.84 2.104 11.92 2.952L2.952 11.92C2.104 10.84 1.6 9.48 1.6 8ZM8 14.4C6.52 14.4 5.16 13.896 4.08 13.048L13.048 4.08C13.896 5.16 14.4 6.52 14.4 8C14.4 11.536 11.536 14.4 8 14.4Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  View Matched Caregivers
                </button>
              </div>
            </div>
          </template>
        </UniversalTable>
      </div>
</template>

<script>
import { ref, onMounted } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Request from "@/utils/apiclient.js";
import UniversalTable from "@/components/UniversalTable.vue";
import ToastManagerVue from "../../../components/ToastAlert/ToastManager.vue";
import { formatErrorMessages } from "@/utils/errorHandler";
export default {
  name: "AdminTerminationRequests",
  components: {
    LoadingSpinner,
    ToastManagerVue,
    UniversalTable
  },
  setup() {
    const toastManager = ref(null);
    const showToast = (msg, statusValue) => {
      toastManager.value.addToast(msg, statusValue);
    };
    const isLoading = ref(true);
    const caregivers = ref({});
    const isOpen = ref({
      category: false,
    });
    const actionDropdown = ref(null);

    const filters = ref({
      category: "All categories",
    });

    const toggleDropdown = (dropdown) => {
      isOpen.value[dropdown] = !isOpen.value[dropdown];
    };

    const selectCategory = (category) => {
      filters.value.category = category;
      isOpen.value.category = false;
    };

    const toggleDelistDropdown = (index) => {
      actionDropdown.value = actionDropdown.value === index ? null : index;
    };

    const isLastCategory = (categories, category) => {
      return categories.indexOf(category) === categories.length - 1;
    };

    const fetchDashboard = () => {
      const request = new Request("/admin/hire/");
      request.get((res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error:", err);
        } else {
          console.log("here is  admin hire", res);
          // caregivers.value = res;
          caregivers.value = res.sort((a, b) => a.id - b.id);
        }
      });
    };

    onMounted(() => {
      fetchDashboard();
    });

    const handleApprove = async (status, id) => {
      isLoading.value = true;

      // Handle login logic here
      const formData = {
        status: status == "approve" ? 3 : 1,
      };

      const request = new Request(`/admin/hire/${id}/`);
      const rawData = JSON.stringify(formData);
      console.log("raw data", rawData);
      request.patch(rawData, (data, error) => {
        isLoading.value = false;
        if (error) {
          console.log("data error", error);
          if (error.status == "failure") {
            return showToast(error.message, "error");
          }
          if (error.detail) {
            return showToast("Error delisting careseeker", "error");
          }
          const errorMessages = formatErrorMessages(error);
          console.log("error", errorMessages);
          errorMessages.forEach((message) => showToast(message, "error"));
        } else {
          console.log("Success:", data);

          showToast(`Careseeker termination request ${status}d`, "success");
          fetchDashboard();
        }
      });
    };

    const endpoint = "/admin/hire/";

    const columns = [
      { label: "ID", field: "id" },
      {
        label: "Careseeker Name",
        field: "name",
        render: (row) => `${row.care_seeker.user.first_name} ${row.care_seeker.user.last_name}`,
      },
      {
        label: "Caregiver Name",
        field: "name",
        render: (row) => `${row.care_giver.user.first_name} ${row.care_giver.user.last_name}`,
      },
      { label: "Termination Reason", field: "termination_reason" },
      { label: "Action", field: "action" },
    ];

    return {
      caregivers,
      isOpen,
      filters,

      toggleDropdown,
      selectCategory,
      toggleDelistDropdown,

      actionDropdown,
      isLoading,
      isLastCategory,
      toastManager,
      handleApprove,
      columns,
      endpoint
    };
  },
};
</script>

<style scoped>
.main-table-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 1008px;
  margin-top: 52px;
}

.filter-container {
  margin-bottom: 20px;
}

.custom-select-wrapper {
  position: relative;
  display: inline-block;
  width: 340px;
}

.custom-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #979797;
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
}

.custom-options {
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 340px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  margin-top: 8px;
  z-index: 10;
  list-style-type: none;
  padding: 0;
}

.custom-options li {
  padding: 8px;
  cursor: pointer;
}

.custom-options li:hover,
.custom-options li.selected {
  background-color: #f4f4f4;
}

.careseekers-table {
  width: 100%;

  border-spacing: 0 13px;
  /* opacity: 0.5; */
}
.careseekers-table-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-radius: 12px;
  padding: 24px;
  background-color: #ececec80;
  min-height: 772px;
  margin-bottom: 400px;
}

.careseekers-table th {
  /* opacity: 1; */
  padding: 8px;

  font-size: 16px;
  font-weight: 500;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}

.careseekers-table td {
  padding: 8px;

  font-size: 16px;
  font-weight: 400;

  letter-spacing: 0.5px;
  text-align: left;
}

.careseekers-table th {
  background-color: #f4f4f4;
}

.careseekers-table td.active {
  width: 70px;
  height: 35px;
  background-color: var(--state-success);
  color: var(--state-white);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.careseekers-table td.inactive {
  width: 83px;
  height: 35px;
  padding: 8px;
  background-color: #979797;
  color: var(--state-white);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.dropdown-menu {
  position: absolute;
  right: 20px; /* Align to the right edge of the icon-dots container */
  top: -100px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0px 2px 4px 0px #0000001a;
  padding: 10px;
  transform: translateY(100%); /* Position below the icon-dots */
  z-index: 10;
}

.dropdown-menu button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px 8px;
  width: 100%;
  text-align: left;
}

.dropdown-menu .icon {
  margin-right: 8px;
}

.selected-option {
  display: flex;
  align-items: center;
}

.selected-option svg {
  margin-right: 8px;
}
.the-td {
  position: relative;
}
.icon-dots {
  display: flex;
  padding-left: 12px;

  cursor: pointer;
}
/* Pagination styles (commented out for now) */
/*
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination button {
  padding: 8px 16px;
  border: 1px solid #ddd;
  background-color: white;
  cursor: pointer;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
*/
@media (max-width: 800px) {
  .main-table-container {
    width: 280px;
  }
  .careseekers-table {
    border-spacing: 0px 5px;
  }

  .careseekers-table td.active {
    width: 30px;
    height: 25px;
    background-color: var(--state-success);
    color: var(--state-white);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  .careseekers-table td.inactive {
    width: 33px;
    height: 25px;
    padding: 8px;
    background-color: #979797;
    color: var(--state-white);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  .careseekers-table th {
    /* opacity: 1; */
    padding: 8px;

    font-size: 7px;
    font-weight: 500;
    line-height: 27.2px;
    letter-spacing: 0.5px;
    text-align: left;
  }

  .careseekers-table td {
    padding: 8px;

    font-size: 7px;
    font-weight: 400;

    letter-spacing: 0.5px;
    text-align: left;
  }
  .custom-select-wrapper {
    width: 200px;
  }
}
</style>
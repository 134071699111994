<template>
  <DashboardLayout>
    <LoadingSpinner :isLoading="isLoading" />
    <!-- start large screen -->
    <div v-if="isLargeScreen" class="overall-container">
      <div class="main-container">
        <div v-if="!noMessages" class="messagesss">Messages</div>
        <div v-if="noMessages" class="no-messages">
          You have no messages yet
        </div>
        <div v-else class="messages-container">
          <div class="messages-container-first">
            <!-- <div class="search-wrapper">
              <input type="text" placeholder="Search" v-model="searchQuery" />
              <svg
                v-if="careType === 'caregiver'"
                class="search-icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.1372 14.0926L24 21.9554L21.9554 24L14.0926 16.1372C12.6244 17.1938 10.8542 17.8388 8.91938 17.8388C3.99314 17.8388 0 13.8456 0 8.91938C0 3.99314 3.99314 0 8.91938 0C13.8456 0 17.8388 3.99314 17.8388 8.91938C17.8388 10.8542 17.1938 12.6244 16.1372 14.0926ZM8.91938 2.74443C5.50257 2.74443 2.74443 5.50257 2.74443 8.91938C2.74443 12.3362 5.50257 15.0943 8.91938 15.0943C12.3362 15.0943 15.0943 12.3362 15.0943 8.91938C15.0943 5.50257 12.3362 2.74443 8.91938 2.74443Z"
                  fill="#979797"
                />
              </svg>
              <svg
                v-else
                class="search-icon"
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  y="0.253906"
                  width="37.49"
                  height="37.49"
                  rx="18.745"
                  fill="#F59CAB"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M21.76 20.5239L27.49 26.2539L26 27.7439L20.27 22.0139C19.2 22.7839 17.91 23.2539 16.5 23.2539C12.91 23.2539 10 20.3439 10 16.7539C10 13.1639 12.91 10.2539 16.5 10.2539C20.09 10.2539 23 13.1639 23 16.7539C23 18.1639 22.53 19.4539 21.76 20.5239ZM16.5 12.2539C14.01 12.2539 12 14.2639 12 16.7539C12 19.2439 14.01 21.2539 16.5 21.2539C18.99 21.2539 21 19.2439 21 16.7539C21 14.2639 18.99 12.2539 16.5 12.2539Z"
                  fill="white"
                />
              </svg>
            </div> -->
            <div class="messages-item-list">
              <MessageItem
                v-for="(messageGroup, groupName) in messagesList"
                :key="groupName"
                :userCareType="user.type"
                :messageGroup="messageGroup"
                :groupName="groupName"
                :selectedMessage="selectedMessageRoomName"
                :selectedMessageRoom="selectedMessageRoom"
                @select="handleSelect"
              />
            </div>
          </div>
          <div v-if="isMessageClicked">
            <div class="messages-container-second">
              <div class="messages-list">
                <div v-if="noConnectionError">
                  <div class="messages-list-header">
                    <div>
                      <Avatar :initials="recipientInitials" />
                      <h2>{{ recipientName }}</h2>
                    </div>
                    <div class="date-separator">
                      <p>{{ formattedDate }}</p>
                      <hr />
                    </div>
                  </div>
                  <MainMessageItem
                    v-for="(message, index) in mainMessages"
                    :key="index"
                    :name="message.sender.full_name"
                    :date="message.timestamp"
                    :time="new Date(message.timestamp).toLocaleTimeString()"
                    :message="message.message"
                    :avatarSrc="message.sender.profile_picture"
                    :initials="
                      message.sender.full_name.split(' ')[0].charAt(0) +
                      message.sender.full_name.split(' ').slice(-1)[0].charAt(0)
                    "
                    :reply="message.reply"
                    :senderType="message.sender.type"
                  />
                </div>
                <div
                  v-else
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                  "
                >
                  There was an error connecting to the server
                </div>

                <!-- <div v-for="(message, index) in mainMessages" :key="index">
                {{ message.message }}
              </div> -->
              </div>

              <div class="message-input-container">
                <input
                  type="text"
                  placeholder="Type message here"
                  v-model="message"
                  @keyup.enter="sendMessage"
                />
                <div class="button-icons">
                  <div class="icons">
                    <!-- <svg
                    @click="handleAttachment"
                    width="11"
                    height="20"
                    viewBox="0 0 11 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 14.5C11 16.0333 10.4667 17.3333 9.4 18.4C8.33333 19.4667 7.03333 20 5.5 20C3.96667 20 2.66667 19.4667 1.6 18.4C0.533334 17.3333 4.7302e-08 16.0333 6.55868e-08 14.5L1.90798e-07 4C2.03915e-07 2.9 0.391667 1.95833 1.175 1.175C1.95833 0.391666 2.9 3.45822e-08 4 4.76995e-08C5.1 6.08169e-08 6.04167 0.391666 6.825 1.175C7.60833 1.95833 8 2.9 8 4L8 13.5C8 14.2 7.75833 14.7917 7.275 15.275C6.79167 15.7583 6.2 16 5.5 16C4.8 16 4.20833 15.7583 3.725 15.275C3.24167 14.7917 3 14.2 3 13.5L3 4.75C3 4.53333 3.07067 4.354 3.212 4.212C3.354 4.07067 3.53333 4 3.75 4C3.96667 4 4.14567 4.07067 4.287 4.212C4.429 4.354 4.5 4.53333 4.5 4.75L4.5 13.5C4.5 13.7833 4.59567 14.0207 4.787 14.212C4.979 14.404 5.21667 14.5 5.5 14.5C5.78333 14.5 6.02067 14.404 6.212 14.212C6.404 14.0207 6.5 13.7833 6.5 13.5L6.5 4C6.5 3.3 6.25833 2.70833 5.775 2.225C5.29167 1.74167 4.7 1.5 4 1.5C3.3 1.5 2.70833 1.74167 2.225 2.225C1.74167 2.70833 1.5 3.3 1.5 4L1.5 14.5C1.5 15.6 1.89167 16.5417 2.675 17.325C3.45833 18.1083 4.4 18.5 5.5 18.5C6.6 18.5 7.54167 18.1083 8.325 17.325C9.10833 16.5417 9.5 15.6 9.5 14.5L9.5 4.75C9.5 4.53333 9.57067 4.354 9.712 4.212C9.854 4.07067 10.0333 4 10.25 4C10.4667 4 10.646 4.07067 10.788 4.212C10.9293 4.354 11 4.53333 11 4.75L11 14.5Z"
                      fill="#979797"
                    />
                  </svg> -->

                    <svg
                      @click="handleEmoji"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_281_13192)">
                        <path
                          d="M15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11Z"
                          fill="#979797"
                        />
                        <path
                          d="M8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11Z"
                          fill="#979797"
                        />
                        <path
                          d="M12 18C14.28 18 16.22 16.34 17 14H7C7.78 16.34 9.72 18 12 18Z"
                          fill="#979797"
                        />
                        <path
                          d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                          fill="#979797"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_281_13192">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>

                  <button @click="sendMessage">
                    Send
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_281_13204)">
                        <path
                          d="M2.01 21L23 12L2.01 3L2 10L17 12L2 14L2.01 21Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_281_13204)">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
                <div class="emojiii-picker" v-if="isEmoji">
                  <EmojiPicker
                    :native="true"
                    @select="onSelectEmoji"
                    :hide-group-icons="true"
                    :hide-group-names="true"
                    :disable-skin-tones="true"
                    :hide-search="true"
                    :disable-sticky-group-names="true"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="messages-list"
            style="
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            Click on a
            {{ user.type == "Care seeker" ? "Care giver" : "Care seeker" }} to
            continue your chat
          </div>
        </div>
      </div>
    </div>
    <!-- end large screen -->

    <!-- start small screen -->
    <div v-else>
      <div class="overall-container">
        <div class="main-container">
          <div v-if="noMessages" class="no-messages">
            You have no messages yet
          </div>
          <div v-else class="messages-container">
            <div class="messages-container-first">
              <div v-if="messagesSideBar" @click="backToDashboard" class="messages-back">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 14L0 7L7 0L8.24687 1.225L3.34687 6.125H14V7.875H3.34687L8.24687 12.775L7 14Z"
                    fill="black"
                  />
                </svg>
                Messages
              </div>

              <!-- <div class="search-wrapper">
                <input type="text" placeholder="Search" v-model="searchQuery" />
                <svg
                  v-if="careType === 'caregiver'"
                  class="search-icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.1372 14.0926L24 21.9554L21.9554 24L14.0926 16.1372C12.6244 17.1938 10.8542 17.8388 8.91938 17.8388C3.99314 17.8388 0 13.8456 0 8.91938C0 3.99314 3.99314 0 8.91938 0C13.8456 0 17.8388 3.99314 17.8388 8.91938C17.8388 10.8542 17.1938 12.6244 16.1372 14.0926ZM8.91938 2.74443C5.50257 2.74443 2.74443 5.50257 2.74443 8.91938C2.74443 12.3362 5.50257 15.0943 8.91938 15.0943C12.3362 15.0943 15.0943 12.3362 15.0943 8.91938C15.0943 5.50257 12.3362 2.74443 8.91938 2.74443Z"
                    fill="#979797"
                  />
                </svg>
                <svg
                  v-else
                  class="search-icon"
                  width="38"
                  height="38"
                  viewBox="0 0 38 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    y="0.253906"
                    width="37.49"
                    height="37.49"
                    rx="18.745"
                    fill="#F59CAB"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21.76 20.5239L27.49 26.2539L26 27.7439L20.27 22.0139C19.2 22.7839 17.91 23.2539 16.5 23.2539C12.91 23.2539 10 20.3439 10 16.7539C10 13.1639 12.91 10.2539 16.5 10.2539C20.09 10.2539 23 13.1639 23 16.7539C23 18.1639 22.53 19.4539 21.76 20.5239ZM16.5 12.2539C14.01 12.2539 12 14.2639 12 16.7539C12 19.2439 14.01 21.2539 16.5 21.2539C18.99 21.2539 21 19.2439 21 16.7539C21 14.2639 18.99 12.2539 16.5 12.2539Z"
                    fill="white"
                  />
                </svg>
              </div> -->
              <div v-if="messagesSideBar" class="messages-item-list">
                <MessageItem
                  v-for="(messageGroup, groupName) in messagesList"
                  :key="groupName"
                  :userCareType="user.type"
                  :messageGroup="messageGroup"
                  :groupName="groupName"
                  :selectedMessage="selectedMessageRoomName"
                  :selectedMessageRoom="selectedMessageRoom"
                  :isLargeScreen="isLargeScreen"
                  @select="handleSelect"
                />
              </div>
            </div>
            <div v-if="isMessageClicked">
              <div v-if="noConnectionError" class="messages-container-second">
                <div class="handle-message-back" >
                  <svg
                    @click="handleMessageBack"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 14L0 7L7 0L8.24687 1.225L3.34687 6.125H14V7.875H3.34687L8.24687 12.775L7 14Z"
                      fill="black"
                    />
                  </svg>

                  <Avatar :initials="recipientInitials" />
                  <h2>{{ recipientName }}</h2>
                </div>
                <div class="messages-list">
                  <div>
                    <div class="messages-list-header">
                      <div class="date-separator">
                        <p>{{ formattedDate }}</p>
                        <hr />
                      </div>
                    </div>
                    <MainMessageItem
                      v-for="(message, index) in mainMessages"
                      :key="index"
                      :name="message.sender.full_name"
                      :date="message.timestamp"
                      :time="new Date(message.timestamp).toLocaleTimeString()"
                      :message="message.message"
                      :avatarSrc="message.sender.profile_picture"
                      :initials="
                        message.sender.full_name.split(' ')[0].charAt(0) +
                        message.sender.full_name
                          .split(' ')
                          .slice(-1)[0]
                          .charAt(0)
                      "
                      :reply="message.reply"
                      :senderType="message.sender.type"
                    />
                  </div>
                
                </div>

                <div class="message-input-container">
                  <input
                    type="text"
                    placeholder="Type message here"
                    v-model="message"
                    @keyup.enter="sendMessage"
                  />
                  <div class="button-icons">
                    <div class="icons">
                   
                      <svg
                        @click="handleEmoji"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_281_13192)">
                          <path
                            d="M15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11Z"
                            fill="#979797"
                          />
                          <path
                            d="M8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11Z"
                            fill="#979797"
                          />
                          <path
                            d="M12 18C14.28 18 16.22 16.34 17 14H7C7.78 16.34 9.72 18 12 18Z"
                            fill="#979797"
                          />
                          <path
                            d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                            fill="#979797"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_281_13192">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>

                  
                      <!-- Send -->
                      <svg
                      @click="sendMessage"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_281_13204)">
                          <path
                            d="M2.01 21L23 12L2.01 3L2 10L17 12L2 14L2.01 21Z"
                            fill="#979797"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_281_13204)">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                   
                  </div>
                  <div class="emojiii-picker" v-if="isEmoji">
                    <EmojiPicker
                      :native="true"
                      @select="onSelectEmoji"
                      :hide-group-icons="true"
                      :hide-group-names="true"
                      :disable-skin-tones="true"
                      :hide-search="true"
                      :disable-sticky-group-names="true"
                    />
                  </div>
                </div>
              </div>
                <div
                    v-else
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      height: 100%;
                    "
                  >
                    There was an error connecting to the server
                  </div>
            </div>
            <!-- <div
              v-else
              class="messages-list"
              style="
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              Click on a
              {{ user.type == "Care seeker" ? "Care giver" : "Care seeker" }} to
              continue your chat
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- end small screen -->
  </DashboardLayout>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import MessageItem from "@/components/MessageItem.vue";
import Avatar from "@/components/AvatarComponent.vue";
import DashboardLayout from "@/views/Dashboard/Caregiver/IndexPage.vue";
import Request from "@/utils/apiclient.js";
import MainMessageItem from "@/components/MainMessageItem.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import EmojiPicker from "vue3-emoji-picker";
import { useRouter } from "vue-router";
// import css
import "vue3-emoji-picker/css";
export default {
  name: "MessagesPage",
  components: {
    MessageItem,
    Avatar,
    DashboardLayout,
    LoadingSpinner,
    MainMessageItem,
    EmojiPicker,
  },
  setup() {
    const router = useRouter();
    const isLargeScreen = ref(window.innerWidth > 1200);

    const handleResize = () => {
      isLargeScreen.value = window.innerWidth > 1200;
      console.log("inner", window.innerWidth);
    };
    const noConnectionError = ref(null);
    const isMessageClicked = ref(null);
    const isLoading = ref(true);
    let currentDate = new Date();

    // Get the day of the week (short form)
    let dayOfWeek = currentDate.toLocaleString("en-US", { weekday: "short" });

    // Get the month (short form)
    let month = currentDate.toLocaleString("en-US", { month: "short" });

    // Get the day of the month
    let dayOfMonth = currentDate.getDate();

    // Get the year
    let year = currentDate.getFullYear();

    // Construct the formatted date string
    let formattedDate = `${dayOfWeek} ${month} ${dayOfMonth} ${year}`;

    const careType = ref("careseeker");
    const selectedMessageRoomName = ref({});
    const selectedMessageRoom = ref({});
    const searchQuery = ref("");

    const messagesList = ref({});
    const mainMessages = ref([]);
    const message = ref("");

    const recipientName = ref(null);
    // const recipientProfilePic = ref(null);
    const recipientInitials = ref(null);

    const userToken = localStorage.getItem("_user");
    const user = JSON.parse(localStorage.getItem("_userProfile"));

    let socket;

    const connectWebSocket = () => {
      // Close existing WebSocket if any
      if (socket) {
        console.log(
          "Closing existing WebSocket connection before opening a new one."
        );
        socket.removeEventListener("open", handleWebSocketOpen);
        socket.removeEventListener("message", handleWebSocketMessage);
        socket.removeEventListener("error", handleWebSocketError);
        socket.removeEventListener("close", handleWebSocketClose);
        socket.close();
      }

      // Proceed to create a new WebSocket connection
      const parts = selectedMessageRoom.value.group_name.split("_");
      const socketUrl = `wss://api-dev.succour.care/ws/chat/${
        parts[parts.length - 2]
      }_${parts[parts.length - 1]}/?token=${userToken}`;
      console.log("Attempting WebSocket connection to:", socketUrl);
      socket = new WebSocket(socketUrl);

      // Add event listeners
      socket.addEventListener("open", handleWebSocketOpen);
      socket.addEventListener("message", handleWebSocketMessage);
      socket.addEventListener("error", handleWebSocketError);
      socket.addEventListener("close", handleWebSocketClose);
    };

    // WebSocket Open Handler
    const handleWebSocketOpen = () => {
      console.log("WebSocket connection opened.");
      try {
        // Validate selectedMessageRoom and group_name
        if (
          !selectedMessageRoom.value ||
          !selectedMessageRoom.value.group_name
        ) {
          throw new Error("Selected message room or group_name is undefined.");
        }

        // Check if the socket is open
        if (socket.readyState === WebSocket.OPEN) {
          const message = JSON.stringify({
            type: "join",
            room: selectedMessageRoom.value.group_name,
          });
          console.log("mess", message);
          socket.send(message);
          noConnectionError.value = true;
          isMessageClicked.value = true;
        } else {
          throw new Error(
            `WebSocket is not open: readyState=${socket.readyState}`
          );
        }
      } catch (error) {
        console.error("Error in handleWebSocketOpen:", error);
        noConnectionError.value = false;
        // Optionally notify the user about the error
        // alert("Failed to join the chat room. Please try again.");
      }
    };

    const handleWebSocketMessage = (e) => {
      try {
        const msg = JSON.parse(e.data);
        console.log("Received message:", msg);

        if (selectedMessageRoom.value.group_name) {
          // Create a unique key for the message
          const msgKey = `${msg.timestamp}_${msg.sender.full_name}_${msg.message}`;

          // Check if the message already exists in mainMessages
          const messageExists = mainMessages.value.some((message) => {
            const existingMsgKey = `${message.timestamp}_${message.sender.full_name}_${message.message}`;
            return existingMsgKey === msgKey;
          });

          if (!messageExists) {
            mainMessages.value.push(msg);
          } else {
            console.log("Duplicate message detected, ignoring.");
          }
        } else {
          console.log("Received message but room does not match:", msg.room);
        }
      } catch (error) {
        console.error("Error parsing message:", error);
      }
    };

    // WebSocket Error Handler
    const handleWebSocketError = (e) => {
      console.error("WebSocket error occurred:", e);
      // Optionally notify the user
      // alert("WebSocket connection error. Please try again later.");
      noConnectionError.value = false;
    };

    // WebSocket Close Handler
    const handleWebSocketClose = (e) => {
      console.log("WebSocket connection closed.", e);
      if (!e.wasClean) {
        console.error("WebSocket closed unexpectedly:", e);
        // Optionally attempt to reconnect after a delay
        setTimeout(() => {
          connectWebSocket();
        }, 5000); // Reconnect after 5 seconds
      }
    };
    const messagesSideBar = ref(true);
    // Handle selecting a message
    const handleSelect = (val) => {
      if (!isLargeScreen.value) {
        messagesSideBar.value = false;
      }
      // Check if the selected room is the same as the current room
      console.log("val", val);
      if (selectedMessageRoom.value.group_name === val.group[0].group_name) {
        console.log("Already connected to this room. No action taken.");
        return; // Do nothing
      }

      isLoading.value = true;

      // Close existing WebSocket if any
      if (socket) {
        console.log("Closing existing WebSocket connection.");

        // Send "leave" message to the server if possible
        if (socket.readyState === WebSocket.OPEN) {
          socket.send(
            JSON.stringify({
              type: "leave",
              room: selectedMessageRoom.value.group_name,
            })
          );
        }

        // Remove event listeners
        socket.removeEventListener("open", handleWebSocketOpen);
        socket.removeEventListener("message", handleWebSocketMessage);
        socket.removeEventListener("error", handleWebSocketError);
        socket.removeEventListener("close", handleWebSocketClose);

        // Close the socket
        socket.close();
      }

      // Clear previous messages
      mainMessages.value = [];

      selectedMessageRoom.value = { ...val.group[0] };
      selectedMessageRoomName.value = val.groupName;
      if (user.type === "Care giver") {
        if (selectedMessageRoom.value.recipient.type == "Care seeker") {
          console.log("got here");
          recipientName.value =
            selectedMessageRoom.value.recipient.first_name +
            " " +
            selectedMessageRoom.value.recipient.last_name;
          recipientInitials.value =
            selectedMessageRoom.value.recipient.first_name.charAt(0) +
            selectedMessageRoom.value.recipient.last_name.charAt(0);
        } else if (selectedMessageRoom.value.sender.type == "Care seeker") {
          console.log("got here2");
          recipientName.value =
            selectedMessageRoom.value.sender.first_name +
            " " +
            selectedMessageRoom.value.sender.last_name;
          recipientInitials.value =
            selectedMessageRoom.value.sender.first_name.charAt(0) +
            selectedMessageRoom.value.sender.last_name.charAt(0);
        }
      } else {
        if (selectedMessageRoom.value.recipient.type == "Care giver") {
          console.log("got here3");
          recipientName.value =
            selectedMessageRoom.value.recipient.first_name +
            " " +
            selectedMessageRoom.value.recipient.last_name;
          recipientInitials.value =
            selectedMessageRoom.value.recipient.first_name.charAt(0) +
            selectedMessageRoom.value.recipient.last_name.charAt(0);
        } else if (selectedMessageRoom.value.sender.type == "Care giver") {
          console.log("got here4");
          recipientName.value =
            selectedMessageRoom.value.sender.first_name +
            " " +
            selectedMessageRoom.value.sender.last_name;
          recipientInitials.value =
            selectedMessageRoom.value.sender.first_name.charAt(0) +
            selectedMessageRoom.value.sender.last_name.charAt(0);
        }
      }

      connectWebSocket();
      isLoading.value = false;
      console.log("Selected room:", val);
    };

    // Cleanup WebSocket connection when the component unmounts
    onBeforeUnmount(() => {
      if (socket) {
        socket.send(
          JSON.stringify({
            type: "leave",
            room: selectedMessageRoom.value.group_name,
          })
        );
        socket.removeEventListener("open", handleWebSocketOpen);
        socket.removeEventListener("message", handleWebSocketMessage);
        socket.removeEventListener("error", handleWebSocketError);
        socket.removeEventListener("close", handleWebSocketClose);
        socket.close();
      }
    });

    // ...
    function onSelectEmoji(emoji) {
      console.log("em", emoji);
      message.value += emoji.i;
    }
    // Sending Messages
    const sendMessage = () => {
      // Split the string by underscore
      const parts = selectedMessageRoom.value.group_name.split("_");
      console.log("partsss", parts[parts.length - 1], parts[parts.length - 2]);
      const msg = {
        type: "message", // Or whatever the server expects
        message: message.value,
        recipient:
          user.type === "Care giver"
            ? parts[parts.length - 2]
            : parts[parts.length - 1],
        room: selectedMessageRoom.value.group_name,
      };

      if (socket.readyState === 1) {
        console.log("Sending message:", msg);
        socket.send(JSON.stringify(msg));
        message.value = ""; // Clear input after sending
      } else {
        console.warn("WebSocket is not open. Ready state:", socket.readyState);
      }
    };

    // Handle selecting a message
    // const handleSelect = (val) => {

    //   selectedMessageRoomName.value = val;
    //   connectWebSocket();
    //   console.log("value ooooooo", val);
    // };
    const noMessages = ref(false);
    const fetchMessages = () => {
      const request = new Request("/message/");
      request.get((res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error messages:", err);
        } else {
          console.log("here messages", res);
          messagesList.value = { ...res.data };
          if (Object.keys(res.data).length === 0) {
            console.log("hiiii");
            noMessages.value = true;
          } else {
            console.log("hiiiiyooo");
            noMessages.value = false;
          }
        }
      });
    };

    // Lifecycle hook to connect WebSocket on component mount
    onMounted(() => {
      //connectWebSocket();
      fetchMessages();
    });
    onBeforeUnmount(() => {
      if (socket) {
        if (socket.readyState === WebSocket.OPEN) {
          socket.send(
            JSON.stringify({
              type: "leave",
              room: selectedMessageRoom.value.group_name,
            })
          );
        }
        socket.removeEventListener("open", handleWebSocketOpen);
        socket.removeEventListener("message", handleWebSocketMessage);
        socket.removeEventListener("error", handleWebSocketError);
        socket.removeEventListener("close", handleWebSocketClose);
        socket.close();
      }
    });
    const isEmoji = ref(false);
    const handleEmoji = () => {
      isEmoji.value = !isEmoji.value;
    };
    const handleMessageBack = () => {
      isMessageClicked.value = false;
      messagesSideBar.value = true;
    };
    const backToDashboard = () => {
      if (user.type == "Care seeker") {
        router.push("/careseeker/home");
      } else {
        router.push("/caregiver/home");
      }
    };
    return {
      messagesList,
      mainMessages,
      handleSelect,
      selectedMessageRoomName,
      selectedMessageRoom,
      careType,
      searchQuery,
      message,
      sendMessage,

      recipientName,
      recipientInitials,
      formattedDate,
      noMessages,
      isLoading,
      isMessageClicked,
      noConnectionError,
      onSelectEmoji,
      isEmoji,
      handleEmoji,
      user,
      isLargeScreen,
      handleResize,
      messagesSideBar,
      handleMessageBack,
      backToDashboard,
    };
  },
};
</script>


<style scoped>
.overall-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.messagesss {
  margin-bottom: 8px;
  font-size: 28px;
  font-weight: 700;
  line-height: 36.4px;
  letter-spacing: 0.5px;
  text-align: left;
  width: 1184px;
  box-sizing: border-box;
}
.main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 128px;
  padding-right: 128px;
  align-items: center;
  margin-top: 33px;
  box-sizing: border-box;
}
.messages-container {
  display: flex;
  width: 100%;
  width: 1184px;
  gap: 35px;
  box-sizing: border-box;
}
.messages-container-first {
  display: flex;
  flex-direction: column;
  width: 380px;
  box-sizing: border-box;
}
.messages-container-second {
  display: flex;
  flex-direction: column;
  width: 790px;
  box-sizing: border-box;
  /* max-height: 500px;
    height: 100%;
    overflow-y: auto; */
}
.search-wrapper input {
  border: 1px solid #979797 !important;
  width: 575px;
  height: 64px;
  padding: 10px 10px 10px 70px;
  gap: 16px;
  border-radius: 12px;
  border: 1px;
  box-sizing: border-box;
  background-color: transparent;
}
.search-wrapper input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  color: #979797;
}

.search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}
.search-icon {
  position: absolute;
  left: 20px;
}
.messages-list {
  padding: 20.5px;
  background-color: #ececec80;
  max-height: 500px;
  height: 100%;
  overflow-y: auto;
}
.messages-list-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
}
.messages-list-header div:first-child {
  display: flex;
  align-items: center;
  gap: 8px;
}
.messages-list-header h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
}
.messages-list-header p {
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}
.date-separator {
  display: flex;
  align-items: center;
  width: 100%;
  color: #333;
  margin-top: 15px;
}

.date-separator hr {
  flex-grow: 1;
  border: 0;
  margin-left: 15px;
  border-top: 1px solid #ccc;
}
.message-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: transparent;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 35px;
}

.message-input-container input[type="text"] {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  background-color: transparent;
}
.message-input-container input[type="text"]::placeholder {
  color: #979797;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
}

.message-input-container button {
  background-color: var(--s-green-normal);
  color: white;
  border: none;
  border-radius: 40px;
  padding: 10px;
  margin-left: 10px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
  width: 173px;
  height: 48px;
}

.message-input-container button:hover {
  background-color: var(--s-green-normal-hover);
}

.message-input-container .icons {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.message-input-container .icons svg {
  margin: 0 5px;
  cursor: pointer;
  fill: #888;
}

.message-input-container .icons svg:hover {
  fill: #555;
}
.button-icons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.messages-item-list {
  background-color: var(--s-pink-light);
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  max-height: 547.5px;
  overflow-y: auto;
}
.no-messages {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  width: 100%;
}
.v3-emoji-picker {
  height: 200px;
  width: 100%;
}
.emojiii-picker {
  width: 100%;
  margin-top: 5px;
}
@media (max-width: 1200px) {
  .main-container {
    padding: 0px;
  }
  .messages-container {
    width: 100%;
  
    display: flex;
    flex-direction: column;
  }
  .messages-container-first {
    width: 100%;
  
  }
  .messages-container-second {
    width: 100%;

  }
  .messages-back {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 16px;
    margin-top: 33px;
    padding-left: 16px;
  }
  .main-container{
    margin-top: 0px;
  }
  .handle-message-back{
    display: flex; align-items: center; gap: 10px; background-color: #ECECEC; margin-bottom: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px
  }
h2{
  font-family: Inter;
font-size: 16px;
font-weight: 700;
line-height: 27.2px;
letter-spacing: 0.5px;
text-align: left;

}
.message-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background-color: transparent;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 35px;
  
}
.messages-list{
  max-height: 300px;
}
}
</style>
